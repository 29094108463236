import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const email = params['email'];
      const password = params['password'];
      console.log(email, password);
      if (email && password) {
        this.router.navigate(['/auth/login'], {
          queryParams: { email, password }
        });
      }
    });
  }


  scrollToSection(sectionId: string): void {
    const section = document.getElementById(sectionId);
    console.log(sectionId)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
